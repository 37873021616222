@layer tailwind-base, primereact, tailwind-utilities;

@layer tailwind-base {
  @tailwind base;
}

@layer tailwind-utilities {
  @tailwind components;
  @tailwind utilities;
}

@import "base/reset";

@import "base/flag";

@import "/node_modules/flag-icons/css/flag-icons.min.css";

//prime react
//core
@import "primereact/resources/primereact.min.css";
//theme
@import "primereact/resources/themes/viva-light/theme.css";

//icons
@import "primeicons/primeicons.css";
