@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  font-size: 14px;
}

body {
  background: #ffffff;
}

.card {
  background: #ffffff;
}

html.dark body {
  background: #040d19;
}

html.dark .card {
  background: #071426;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
.loader {
  width: 92.8px;
  height: 46.4px;
  border-radius: 288px 288px 0 0;
  -webkit-mask: repeating-radial-gradient(farthest-side at bottom, #0000 0, #000 1px 12%, #0000 calc(12% + 1px) 20%);
  background: radial-gradient(farthest-side at bottom, #f5793b 0 95%, #0000 0) bottom/0% 0% no-repeat #dbdcef;
  animation: progress-e37qus 2s infinite steps(6);
}
@keyframes progress-e37qus {
  100% {
    background-size: 120% 120%;
  }
}
.p-highlight {
  color: #f5793b !important;
}
.custom-checkbox .p-checkbox-box.p-highlight {
  border-color: #f5793b !important;
  background: #f5793b !important;
}

.custom-checkbox .p-checkbox-box {
  border-color: #f5793b !important;
}

.custom-checkbox .p-checkbox-box .p-checkbox-icon {
  color: white !important;
}
.scrollbar::-webkit-scrollbar {
  display: none !important;
}
.custom-menu a {
  padding: 8px 10px;
  overflow: visible;
}
.scrollbar::-webkit-scrollbar {
  display: none !important;
}

// ---CustomSearchInput---
.css-1xc3v61-indicatorContainer {
  padding: 2px !important;
}
.css-hlgwow {
  padding: 0 0 0 6px !important;
}
.css-19bb58m {
  padding-bottom: 0 !important;
  padding-top: 0 !important;
  margin: 0 !important;
}
.css-13cymwt-control,
.css-t3ipsp-control {
  min-height: 34px !important;
  cursor: text !important;
}
.css-13cymwt-control:hover {
  border-color: #ffab2c !important;
}
.css-1u9des2-indicatorSeparator {
  width: 0 !important;
  margin-bottom: 0 !important;
  margin-top: 0 !important;
}
.select__control--isfocused:focus {
  border-color: transparent !important;
  box-shadow: none !important;
}
.css-1f43avz-a11yText-A11yText:focus {
  border-color: #ffab2c !important;
  cursor: text !important;
}
.css-1f43avz-a11yText-A11yText:hover {
  border-color: #ffab2c !important;
}
.css-1jqq78o-placeholder {
  padding-top: 2px;
  color: hsl(0, 0%, 62%) !important;
  font-size: 13px;
}
.css-1xc3v61-indicatorContainer,
.css-1xc3v61-indicatorContainer:focus,
.select__indicators,
.css-1wy0on6 {
  display: none !important;
}

// Custom styles for react-select
.select {
  &__control {
    &--is-focused,
    &--menu-is-open {
      box-shadow: none !important;
      border-color: inherit !important;
    }
  }

  &__value-container {
    padding: 0 0 0 6px !important;
  }

  &__input-container {
    padding: 0 !important;
    margin: 0 !important;
  }

  &__placeholder {
    margin: 0 !important;
  }

  &__indicator-separator {
    display: none !important;
  }

  &__indicator {
    padding: 0 4px !important;
  }

  &__single-value {
    margin: 0 !important;
  }

  &__menu {
    margin-top: 0 !important;
    border: none !important;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1) !important;
  }

  &__option {
    padding: 8px 12px !important;

    &--is-focused {
      background-color: rgba(0, 0, 0, 0.05) !important;
    }

    &--is-selected {
      background-color: #ffab2c !important;
      color: white !important;
    }
  }
}

// Additional utility classes
.focus\:border-transparent:focus {
  border-color: transparent !important;
}

.focus\:ring-0:focus {
  box-shadow: none !important;
}

.hover\:border-primary:hover {
  border-color: #ffab2c !important;
}

.outline-none {
  outline: none !important;
} /* Styling the dropdown container */
.jexcel .jdropdown-container {
  overflow-y: auto;
  min-width: 300px;
  max-height: 150px; /* Prevents dropdown overflow */
  z-index: 100000000;
  background-color: white;
  position: absolute; /* Ensure it stays above other elements */
}

/* Dropdown item styling */
.jexcel .jdropdown-container .jdropdown-item {
  padding: 5px 10px;
  text-align: left;
  border: 1px solid gray;
  border-left: none;
  border-right: none;
  border-top: none;
}

/* Style for focused/hovered dropdown items */
.jexcel .jdropdown-container .jdropdown-item.jdropdown-cursor,
.jexcel .jdropdown-container .jdropdown-item:hover,
.jexcel .jdropdown-container .jdropdown-item:focus {
  background-color: #ffab2c !important;
  color: white !important;
}

/* Close button for dropdown */
.jexcel .jdropdown-container .jdropdown-close {
  padding: 5px 1px;
  text-align: left;
  border: 1px solid gray;
  border-left: none;
  border-right: none;
  border-top: none;
}

/* Hover effect for dropdown items and close button */
.jexcel .jdropdown-container .jdropdown-item:hover,
.jexcel .jdropdown-container .jdropdown-close:hover {
  cursor: pointer;
}

/* Calendar container styling to avoid overflow issues */
.jcalendar-container {
  background-color: #ffffff;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  padding: 10px;
  width: 250px; /* Fixed width for calendar */
  z-index: 100000000; /* Ensure it’s on top of other elements */
  position: absolute; /* Ensure the calendar stays within the bounds */
  overflow: hidden; /* Prevents the calendar from overflowing */
}

/* Calendar header styling */
.jcalendar-header {
  background-color: #007bff;
  color: #ffffff;
  padding: 10px;
  text-align: center;
  border-radius: 4px;
}

/* Styling day cells */
.jcalendar-day {
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  border: 1px solid transparent;
  cursor: pointer;
  transition: background-color 0.3s;
}

/* Hover effect for day cells */
.jcalendar-day:hover {
  background-color: #007bff;
  color: #ffffff;
  border: 1px solid #0056b3;
}

/* Styling for selected day */
.jcalendar-day.selected {
  background-color: #0056b3;
  color: #ffffff;
}

/* Weekday header styling */
.jcalendar-weekday {
  font-weight: bold;
  color: #333;
  text-align: center;
}

/* Current day highlight */
.jcalendar-day.current {
  background-color: #ffc107;
  color: #ffffff;
  border: 1px solid #ffca2c;
}

/* AG Grid Table Stylings */
.ag-header-container {
  @apply bg-gray-200 font-semibold; /* Background and font styles */
}

.ag-header-cell-resize {
  display: none !important;
}

.ag-cell-inline-editing {
  border-color: lightgray !important;
}
.ag-ltr .ag-cell-focus:not(.ag-cell-range-selected):focus-within,
.ag-ltr .ag-context-menu-open .ag-cell-focus:not(.ag-cell-range-selected),
.ag-ltr .ag-full-width-row.ag-row-focus:focus .ag-cell-wrapper.ag-row-group,
.ag-ltr .ag-cell-range-single-cell,
.ag-ltr .ag-cell-range-single-cell.ag-cell-range-handle,
.ag-rtl .ag-cell-focus:not(.ag-cell-range-selected):focus-within,
.ag-rtl .ag-context-menu-open .ag-cell-focus:not(.ag-cell-range-selected),
.ag-rtl .ag-full-width-row.ag-row-focus:focus .ag-cell-wrapper.ag-row-group,
.ag-rtl .ag-cell-range-single-cell,
.ag-rtl .ag-cell-range-single-cell.ag-cell-range-handle {
  border: 1px solid #007bff !important;
}

.table-icon {
  @apply p-1 text-black cursor-pointer hover:text-primary;
}

.settings_table_header {
  @apply p-[0.5rem] text-[1rem] font-[700] leading-[1.25rem] border-b-2 border-b-secondary;
}

.settings_table_colum {
  @apply border-b-2 border-b-secondary   text-[0.875rem] leading-[1.25rem] py-1.5;
}

.cancel-btn {
  padding: 6px !important;
  border: 0;
  background: #f77171;
  border: 1px solid #f77171 !important;
  &:hover {
    background: transparent !important;
    color: #f77171;
  }
}

.submit-btn {
  padding: 6px !important;
  border: 0;
  background: linear-gradient(83deg, rgb(241, 79, 62) 0%, rgb(250, 183, 104) 100%);
  border: 1px solid #ffab2c !important;
  &:hover {
    background: transparent !important;
    color: #ffab2c;
  }
}

.confirm-btn {
  padding: 6px !important;
  border: 0;
  background: #32de84;
  border: 1px solid #32de84 !important;
  &:hover {
    background: transparent !important;
    color: #32de84;
  }
}

.primary-btn {
  border: 1px solid #ffab2c !important;
  &:hover {
    background: transparent !important;
    color: #ffab2c;
  }
}

.p-datatable-emptymessage {
  td {
    text-align: center !important;
  }
}

.is_vat_true {
  @apply bg-primary border-primary text-white;
}

.is_vat_false {
  @apply bg-gray-200 border-white text-black;
}

.input-field-root {
  width: 100% !important;
  padding: 0 !important;
  border: 1px solid lightgray;
  border-radius: 6px;
  outline: none !important;
  box-shadow: none !important;
  &:hover {
    border: 1px solid #ffab2c !important;
  }
}

.zatca-invoice .ar {
  direction: rtl;
}
